.info{
    height: 100rem;
    width: 101%;
    background-color: rgba( 0,0,0, .98);
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    
  
}
.info_content{
    background-color: lightblue;
    border: 3px solid white;
    height: 40rem;
    width: 70vw;
    padding: 30px;
    position: relative;
    font-weight: bold;
    margin-top: 5%;

}

#button_info{
    position: fixed;
    top: 1rem;
    right: 8.3rem;
    width: 80px;
    height: 35px;
    background-color: blue;
    color: white;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    border: 3px solid white;
    border-radius: 4rem;
}
#med_tag{
    font-size: 30px;
}
.close{
    position: absolute;
    top:0;
    right: 10px;
    text-align: left;
    font-size: 20px;
    font: 200;
    margin: 6px;
}
@media screen and (max-width: 425px){
    #button_info{
        width:50px;
    }
}
@media screen and (min-width: 426px) and (max-width: 699px){
    #button_info{
        width:70px;
    }
}
@media screen and (min-width: 700px){
    #button_info{
        margin-top: 1rem;
        top: .5rem;
        right: 20.3rem;
    }
    .info_content{
        font-size: 22px;
        padding: 40px;
        width: 60vw;
    }
}
