
#about_h3{
    margin: 0;
}
#about_para_1{
    margin-top: 3px;
}
#About_Info{
    width: auto;
    height: 100%;
    margin: 80px 1% 1% 1%;
    padding: 3%;;
color: white;
text-shadow: 1px 2px black,
             0px 2px white;

}

#about_para{
    color: white;
    text-shadow: 1px 1px black,
             0px -1px white,
             -1px -1px black;
    width: auto;
    height: 100vh;
    margin-top: 40px;
    text-align: left;
    padding: 7px;
}

#scout-masters, #eagle-scouts {
    list-style: none;
    text-align: left;
    padding-left:30px ;
}

@media screen and (min-width: 800px){
    #About_Info{
        width: 75%;
        z-index: 0;
        position:relative;
        top: 50%;
        left: 5%;
        height: 125vh;
    }
    #about_para{
        width: 60%;
        display: inline-block;
        padding:4px;
    }
    #text-boxs{
        padding: 1%;
        display: flex;
        flex-direction: row;
        
    }
    
    #leaders{
        margin-left: 9%;
    }
    #scout-masters {
        padding-left: 4%;
    }
    #About_Info{
        
        margin: 100px 1% 1% 1%;
        
    
    }
}