.App {
  flex: 1;
  width: 100%;
  padding: 3px;
  text-align: center;
  position: relative;
}
.Troop_Photo {
  height: 80%;
  width: 80%;
}
#const_logo {
  width: 90vw;
  height: auto;
  object-fit: fill;
  margin: 10px;
  border: 5px solid white;
  box-shadow: -0.5em 0.4em 0.4em rgb(91, 96, 102);
}

#Main_title {
  height: auto;
  margin-top: 105px;
  padding-top: 10px;
  color: white;
  border-bottom: 10px lightgray ridge;
}
.main_button {
  width: 225px;
  height: 40px;
  margin: 15px 0;
  font-size: 20px;
  background-color: red;
  background-image: linear-gradient(to top, black, red);
  font-weight: 700;
  border: 1px solid white;
  color: white;
}
.home-title {
  text-shadow: 1px 1px black, 0px -1px white, -1px -1px black;
  width: 60vw;
  height: 100%;
  margin: 20px auto;
  padding: 7px;
}
.ft_page_main {
  padding-top: 25px;
  font-size: 10px;
}

#duble_ZZ {
  display: flex;
  padding: 0 auto;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 5vh;
}
@media screen and (min-width: 849px) {
  #duble_ZZ {
    height: 100vh;
    flex-direction: row;
  }
}

@media only screen and (min-width: 701px) and (max-width: 850px) and (orientation: portrait) {
  #duble_ZZ {
    margin: 10vh auto;
    padding: 1%;
  }
}
@media only screen and (min-width: 700px) and (max-width: 850px) and (orientation: landscape) {
  #duble_ZZ {
    margin: 25px 0;
    width: 100vw;
    padding: 5px;
  }
}
@media only screen and (min-width: 1368px) and (max-width: 1708px) and (orientation: landscape) {
  #duble_ZZ {
    justify-content: space-evenly;
    padding: 3%;
    width: 60%;
    margin: 0 auto;
  }
}
