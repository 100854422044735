

@media screen and (max-width: 1060px){
    #Contacts_Info{
        width: auto;
        margin: 100px 2% 1% 1%;
        padding-bottom: 3%;
        height: auto;
        background-color: black;
        background-image: linear-gradient(black, rgb(63, 63, 63));
    }
    .j_pucel {
        display: inline-block;
        width: 80%;
        margin: .5rem;
       



    }

    .j_pucel li{
        list-style: none;
        border-radius: 1rem;
        /* border: solid 2px white; */
        box-shadow:  -3px -3px rgb(165, 164, 164), -1.1em .4em .4em rgb(91, 96, 102);
        padding-left: 1rem;
        height: 170px;
        width: 90%;
        text-align: left;
        margin: .5rem;
        background-color: rgba(47, 158, 25);
        padding: 4% 0 10px 4%; 
        

    }
    .contact_name, .j_pucel li{
        font-size: 17px;
    }
    .email{
        width:50%;
    }
}
@media screen and (min-width: 1023px){


#Contacts_Info{
    width: auto;
    margin: 100px 2% 1% 1%;
    height: 100vh;
    padding-bottom: 3%;
    background-color: black;
    /* background-image: linear-gradient(black, rgb(63, 63, 63)); */
}

 .j_pucel{
     
    list-style: none;
    display: grid;
    justify-self: center;
    grid-template-columns: repeat(3, 1fr);
    height: 80vh;
    width: 100%;
}

.j_pucel li{
    /* border: ridge 5px rgb(255, 0, 0); */
    box-shadow:  -3px -3px rgb(165, 164, 164), -1.1em .4em .4em rgb(91, 96, 102);
    border-radius: 1rem;
    height: 170px;
    width: 90%;
    text-align: left;
    margin-left: 5%;
    background-color: rgb(47, 158, 25);
    padding: 4% 0 10px 4%;   
}
.contact_name, .j_pucel li{
    font-size: 21px;
}
}
.email_group{
    display: flex;
    flex-direction: row;
    width: 28%;
}

.email {
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 5px black;
    padding-top: 4px;
}
.email_icon{
    color: rgb(255, 255, 255);
    margin: 1px;
}
.call-button, .phone{
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 5px black;
}
.email, .call-button{
    padding-left: 5px;
    margin: 3px;
}