
#about_h2,#about_h3,#Res_h3,#Res_h4{
    color:white;
}
#Res_h3{
    margin: 0;
}
.sites{
    margin-top: 15px;
    width: 300px;
    padding: 5px 5px 0px 45px;
    text-align: left;
    
}
.link_btn{
    height: 20px;
    width: 180px;
    /* border: 3px solid black;
    background-color: white; */
    line-height: 1em;
    display: inline-block;
    text-decoration: none;
    padding: 6px;
    margin: 4px;
    
}
#Res_Info{
    margin: 0;
    margin-top: 80px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color:black;
    
}
#Res_box {
    margin: 2%;
    display: flex;
    height: 1000px;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
}
#troop_res, #bsa_res{
    margin: 1rem;
    width: 250px;
    height: 78vh;
    background-color: rgb(63, 63, 63);
    /* border: 5px white solid; */
    
}
.sites{
    margin-top: 15px;
    width: 200px;
    padding: 5px 5px 0px 20px;
    text-align: left;
    
}

.link_btn {
    /* RESET */
    text-decoration: none;
    line-height: 1;
    
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 5px 5px;
    overflow: hidden;
    color: rgb(247, 247, 247);
    vertical-align: bottom;
    transition: color .3s ease-out;
 }
 
 .link_btn::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transform: translateY(calc(100% - 2px));
    width: 100%;
    height: 100%;
    background-image: linear-gradient(60deg, #002bff 0%, #ff0000 100%);
    transition: transform .25s ease-out;
 }
 
 .link_btn:hover { 
    color: #fff; 
 }
 .link_btn:hover::before {
    transform: translateY(0);
    transition: transform .25s ease-out;
 }


@media screen and (min-width: 850px){


#Res_Info{
    margin: 100px 1% 1% 1%;
    padding: 0 5%;
    width: auto;
    height: 100vh;
    background-color:black;
}
#Res_box {
    margin: 1rem;
    width: 95%;
    height: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
#troop_res, #bsa_res{
    margin: 3%;
    width: 300px;
    height: 400px;
    padding: 10px;
    
}

#Main_title{
    height: auto;
    padding-top: 10px;
}
}
@media screen and (orientation: landscape) and (min-width: 635px) and (max-width: 900px){
    #troop_res, #bsa_res{
        width: 250px;
        height: 370px;
        
    }
}
@media screen and (orientation: landscape) and (min-width: 735px) and (max-width: 900px){
    #Res_Info{
        margin-top: 100px;
    }
}
@media screen and (orientation: landscape) and (min-width: 567px) and (max-width: 900px){
    #Res_box{
        width: 100%;
        flex-direction: row;
        justify-content: unset;
        align-items: unset;
        height: 500px;
        margin: 5px 12px 0 0;
    }

    #about_h2{
        margin-top: 7rem;
    }
    #troop_res, #bsa_res{
        padding: 10px;
        
    }
}
@media screen and (orientation: landscape) and (min-width: 811px) and (max-width: 900px){
    #Res_box{
        padding-left: 8.5rem;
    }
}
@media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 768px){
    #Res_box{
        width: 100%;
        flex-direction: row;
        justify-content: unset;
        align-items: unset;
        height: 500px;
        margin: 5px 12px 0 0;
        padding-left: 5rem;
    }

    #about_h2{
        margin-top: 7rem;
    }
    #troop_res, #bsa_res{
        padding: 10px;
        height: 300px;
    }
    #Res_Info{
        height: 100vh;
    }
}