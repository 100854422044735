.eventContainer {
  margin: 5vh 0;
  padding: 2%;
  display: grid;
  place-items: center;
}

.m-sale,
.mothers-day-flowers {
  color: white;
}
.mothers-day-flower-img {
  margin: 3%;
  border-radius: 20%;
  border: 1.5em solid transparent;
  background: linear-gradient(#f9f047, #0fd850);
}

@media only screen and (max-width: 550px) {
  .mothers-day-flower-img {
    width: 70vw;
  }
}
