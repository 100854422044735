#leader_list{
    list-style: none;
    text-align: left;
    padding-left: .5rem;
    font-weight: 1000;
    font-family: 'Old Standard TT', serif;
    font-size: 16px;
    text-shadow: 4px 4px 7px white;
    
}
#leader_list li{
    padding:2px;
    position: relative;
}
#leader_box{
    position: absolute;
    width: 75%;  
    font-family: 'Old Standard TT', serif;
    font-weight: 1000;
    padding: 6rem;
}
#leader_box:before{
    content:"";
    background-image: url("http://john-green.net/wp-content/uploads/2015/02/scoutmaster-postition-specific-training.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.50;
}
#scoutimg{
    position: relative;
    width: 200px;
    padding: 10px;
    border-bottom:3px solid black;
    margin-bottom: 1.5rem;
}
#leader_modal{
    width: 100%;
    height: 150%;
    padding: 1%;
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0;
    justify-content: center;
    z-index: 1;
}

#leader_close{
    position: relative;
    top: 0;
    left: 40%;
   height: 35px;
   width: 35px;
}
#ld_modal_title{
    width: 80%;
    font-size: 16px;
    font-weight: 900;
    padding-left: 2rem;
    margin-bottom: 1rem;
    text-shadow: 4px 4px 7px white;
    position: relative;
}
@media screen and (min-width: 700px){
    #leader_list{
        list-style: none;
        text-align: left;
        padding-left: 2rem;
        font-weight: 1000;
        font-family: 'Old Standard TT', serif;
        font-size: 24px;
        background-color: rgba(216, 214, 214, 0.096);
    
        
    }
    #leader_list li{
        padding:3px;
    }
    #leader_list, #ld_modal_title{
        color: rgb(255, 255, 255);
        text-shadow: -2px -2px rgb(0, 0, 0),
        0px 1px rgb(128, 128, 128),
        0px -1px rgb(78, 78, 78),
         2px 0px rgb(65, 65, 65),
         3px 3px rgb(7, 7, 7),
         -2px -2px rgb(0, 0, 0);
    }
    #leader_box{
        font-weight: 1000;
        padding: 5px;
        height: 120vh;
        margin-right: 5rem;
    }
    
    #scoutimg{
        position: relative;
        width: 200px;
        padding: 10px;
        border-bottom:3px solid black;
        margin-bottom: 1.5rem;
    }
    #leader_modal{
        width: 100%;
        height: 100%;
        padding: 5%;
        background-color: rgb(0, 0, 0);
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
    }
    
    
    #ld_modal_title{
        width: 70%;
        font-size: 30px;
        border-bottom: 10px double rgb(255, 255, 255);
        background-color: rgba(223, 223, 223, 0.123);
        font-weight: 900;
        padding-left: 5rem;
        margin-bottom: 4rem;
    }
    
    
}

#button_leader {
    width: 150px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    margin: 2%;
}

#button_leader:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

#button_leader:active {
    color: #000
}

#button_leader:active:after {
    background: transparent;
}

#button_leader:hover:before {
    opacity: 1;
}

#button_leader:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(61, 61, 61);
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
@media screen and (min-width: 700px) and (orientation: landscape){
    #leader_modal{
        margin-top: 4rem;
        height: 300%;
    }

}
@media screen and (max-width: 700px){
    #leader_list, #ld_modal_title{
        color: rgb(255, 255, 255);
        text-shadow: -2px -2px rgb(0, 0, 0),
        0px 1px rgb(128, 128, 128),
        0px -1px rgb(78, 78, 78),
         2px 0px rgb(65, 65, 65),
         3px 3px rgb(7, 7, 7),
         -2px -2px rgb(0, 0, 0);
    }
    #leader_box{
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 1.5rem;
    }
}
@media screen and (max-width: 845px){
    #leader_box{
        margin-top: 6rem;
        margin-right: 6rem;
    }
}
@media screen and (max-width: 359px) {
        #button_leader{
        width: 125px;
        height: 40px;
    }
}