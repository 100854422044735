.sidebar {
  float: left;
  padding: 1.4rem;
  margin: 0;
}

.sidebar-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.sidebar-menu {
  background-image: linear-gradient(
    120deg,
    rgb(3, 16, 248) 10%,
    rgb(76, 76, 76) 90%,
    red 10%
  );
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  border: 2px solid white;
}

.sidebar-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 2;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  margin: 4px;
}
li.nav-text:hover {
  background-image: linear-gradient(
    120deg,
    rgb(3, 16, 248) 10%,
    rgb(76, 76, 76) 90%,
    red 10%
  );
  border: 3px solid rgb(154, 147, 147);
  border-style: ridge;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 98%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 3rem;
}

.nav-text a:hover {
  color: yellow;
  font-size: xx-large;
  margin-right: 10px;
}

.sb-menu-items {
  width: 100%;
}

.sb-toggle {
  background-image: rgb(3, 16, 248);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
#be-prepared {
  position: fixed;
  margin: 2rem auto;
  bottom: 0;
  height: 100px;
  width: 175px;
}
@media screen and (max-width: 700px) {
  .sidebar {
    padding: 16px;
    margin: 2px;
  }
  .sidebar-bars {
    margin: 1.9rem 0 0 0.5rem;
    font-size: 1.7rem;
    background: none;
  }
  .home-info {
    font-size: 16px;
  }
}

@media screen and (orientation: landscape) and (min-width: 639px) {
  .sidebar-menu {
    background-image: linear-gradient(
      120deg,
      rgb(3, 16, 248) 10%,
      rgb(76, 76, 76) 90%,
      red 10%
    );
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    border: 2px solid rgb(0, 0, 0);
  }
  .sb-menu-items {
    width: 90%;
    margin-top: 40px;
  }
  .sb-toggle {
    background-image: rgb(3, 16, 248);
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .nav-text a:hover {
    font-size: 18px;
  }
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    width: 98%;
    height: 80%;
    display: flex;
    align-items: center;
    padding: 0 2px;
    border-radius: 3rem;
  }
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 30px;
    margin: 4px;
  }
  .sidebar-bars {
    margin-bottom: 2rem;
    font-size: 140%;
    background: none;
  }
}
@media screen and (orientation: landscape) and (min-width: 735px) {
  .sidebar-bars {
    margin-bottom: 1rem;
    font-size: 140%;
  }
}
