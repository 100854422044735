#eaglescout_list{
    list-style: none;
    text-align: left;
    padding-left: .5rem;
    font-weight: 1000;
    font-family: 'Old Standard TT', serif;
    font-size: 16px;
    column-count: 2;
}
#eaglescout_list li{
    padding:2px;
    position: relative;
}
#eaglescout_box{
    position: relative;
    border: 5px dashed rgb(142, 0, 0);
    width: 95%;
    margin: 4rem 0 0 13rem;   
    font-family: 'Old Standard TT', serif;
    font-weight: 1200;
    padding: 0.5rem;
color: rgb(255, 255, 255);
text-shadow: 0 1px 0 rgb(11, 0, 0), 
               0 2px 0 #190d0d,
               0 3px 0 rgb(44, 38, 38),
               0 4px 0 #534f4f,
               0 5px 0 rgba(124, 124, 124, 0.519),
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0, 0, 0, 0.183),
               0 3px 5px rgba(0, 0, 0, 0.142),
               0 5px 10px rgba(202, 202, 202, 0.163),
               0 10px 10px rgba(255, 255, 255, 0.149),
               0 20px 20px rgba(217, 217, 217, 0.087);
height: 975px;
}
#eaglescout_box:before{
    content:"";
    background-image: url(../../img/eagle_scout_background2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.50;
}

#eaglescout_modal{
    width: 100%;
    height: 400%;
    z-index: 1;
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0;
    justify-content: center;
    height: 100%;
   
}

#scout_close{
    position: relative;
    top: 0;
    left: 49%;
    background-color: rgb(146, 146, 146);
    border: 5px solid rgb(200, 0, 0);
    border-style: ridge;
   height: 35px;
   width: 35px;
}
.modal_title{
    width: 80%;
    font-size: 16px;
    font-weight: 900;
    padding-left: 2rem;
    padding-top: 5rem;
    margin-bottom: 1rem;
    position:relative;
    margin-top: 5rem;
}
@media screen and (max-width: 699px){
    #eaglescout_box{
        width: 85%;
        margin: 5rem auto;   
        height: 550px;
        background-size: 100% 570px;
        font-size: 12px;
    }
    #eaglescout_modal{
        height: 100%;
    }
    #eaglescout_list{
        font-size: 14px;
    }
    .modal_title{
        font-size: 14px;
        padding-top: .5rem;
        padding-left: .5rem;
    }
    #scout_close{
        left: 40%;
        top: 9%;
        height: 20px;
        width: 20px;
    }
    
}    
@media screen and (min-width: 700px){
    #eaglescout_list{
        list-style: none;
        text-align: left;
        padding-left: 4rem;
        font-weight: 1000;
        font-family: 'Old Standard TT', serif;
        font-size: 20px;
    
        
    }
    #eaglescout_list li{
        padding:3px;
    }
    #eaglescout_box{
        width: 60%;
        font-family: 'Old Standard TT', serif;
        margin: 0;
        font-weight: 1000;
        padding: 2rem;
        left: 15%;
        background-size: 100% 100%;
        height: 100%;

      
    }
    
 
    #eaglescout_modal{
        width: 100%;
        height: 300%;
        padding: 5%;
        background-color: rgb(0, 0, 0);
        position: absolute;
        top: 0;
        justify-content: center;
    }
    
    
    .modal_title{
        width: 70%;
        font-size: 25px;
        font-weight: 900;
        padding-left: 7rem;
        margin-bottom: 4rem;
    }
    
    
}

#button_scout {
    width: 150px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    margin: 2%;
}

#button_scout:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

#button_scout:active {
    color: #000
}

#button_scout:active:after {
    background: transparent;
}

#button_scout:hover:before {
    opacity: 1;
}

#button_scout:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(61, 61, 61);
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
@media screen and (min-width: 700px) and (orientation: landscape){
    #eaglescout_modal{
        margin-top: 4rem;
        height: 200%;
    }
   

}
@media screen and (max-width: 359px) {
    #button_scout{
        width: 125px;
        height: 40px;
    }
}