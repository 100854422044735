#special-dates,
#troop-dates,
#significant-dates {
  text-align: left;
  padding-left: 1%;
}
#troop-dates {
  margin-top: 4%;
}
#events {
  display: inline-block;
  height: auto;
  width: 400px;
  /* border: 5px solid white; */
  background-color: black;
  color:lightpink;
}
#troop__events {
  display: inline-block;
  height: auto;
  width: 400px;
  /* border: 5px solid white; */
  background-color: black;
  color: lightskyblue;
}
#plc__events {
  display: inline-block;
  height: auto;
  width: 400px;
  /* border: 5px solid white; */
  background-color: black;
  color:lightgoldenrodyellow;
}
#significant__events {
  display: inline-block;
  height: auto;
  padding: 5px;
  width: 400px;
  /* border: 5px solid white; */
  background-color: black;
  color:lightseagreen;
}
.dates,
#st_charels,
#camp_phillips {
  display: inline;
  padding: 0 5px;
  font-size: 12px;
}
#st_charels {
  color: white;
}
#events,
#troop__events,
#significant__events,
#plc__events {
  width: 100%;
  padding: 5px;
}
@media only screen and (min-width: 560px) and (max-width: 699px) and (orientation: landscape) {
  #events,
  #troop__events,
  #significant__events,
  #plc__events {
    width: 500px;
    padding: 5px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 850px) and (orientation: landscape) {
  #events,
  #troop__events,
  #significant__events,
  #plc__events {
    width: 93%;
    height: 45vh;
  }
}
@media only screen and (min-width: 700px) and (max-width: 850px) and (orientation: portrait) {
  #events,
  #troop__events,
  #significant__events,
  #plc__events {
    width: 93%;
    margin-bottom: 2%;
  }
  .dates,
  #st_charels,
  .house {
    font-size: 12px;
  }
}
@media only screen and (min-width: 851px) and (max-width: 1025px) and (orientation: portrait) {
  #events,
  #troop__events,
  #significant__events,
  #plc__events {
    width: 93%;
    height: 30vh;
    padding: 5px;
    font-size: 14epx;
  }
  .dates,
  #st_charels,
  .house {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1367px) and (orientation: landscape) {
  #events,
  #troop__events,
  #significant__events,
  #plc__events {
    width: 475px;
    height: 250px;
    padding: 0 5px;
  }
}
