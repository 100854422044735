.ft_page_main{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.foot_tag_p{
    position: bottom;
    margin: 4px;
}
#eaglescout_box{
    z-index: 1;

}
#scout_counsil_logo{
    position: relative;
    margin-left: .5rem;
    width: auto;
    height: 4.5rem;
}
#scout_logo_phrase2{
    position: relative;
    margin-left: .5rem;
    width: auto;
    height: 4.5rem;
}
#foot_link1, #foot_link2, #foot_link3{
    text-decoration: none;
    color: white;
}
#foot_link{
    list-style-type: none;
    text-align: center;
}
@media screen and  (max-width: 725px),(min-width: 500px){
    .ft_page_main{
     display: inline-block;
     height: auto;
    
     padding: 10px;
    }
    #scout_counsil_logo{  
        width: 7rem;
        height: auto;
        float: left;
        margin-right: 3rem;
    }

    #foot_link, #footer-text-title{
        text-align: left;

    }
    #scout_logo_phrase2{  
        width: 7rem;
        height: auto;
        float: right;
        margin-right: 2rem;
    }
}
@media screen and (min-width: 925px){
    .ft_page_main{
     display: inline-block;
     height: auto;
     padding: 10px;
    }
    #scout_counsil_logo{  
        width: 10rem;
        height: auto;
        float: left;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    #footer_text{
        flex: 0 0 25%;
        max-width: 25%;
        padding: 12px 20px;
        font-size: 16px;
        margin-left: 30rem;
        color: white;
    }
    #foot_link, #footer-text-title{
        text-align: left;

    }
    .foot_tag_p{
        text-align: center;
    }
    #scout_logo_phrase2{  
        width: 10rem;
        height: auto;
        float: right;
        margin-top: 2rem;
        margin-right: 2rem;
    }
    #scout_logo_phrase2,#scout_counsil_logo{
        border-radius: 2rem;

    } 
}
