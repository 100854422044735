@media screen and (min-width: 700px) {
  .photo_test {
    border: 2px solid white;
    height: 300px;
    width: 300px;
    position: absolute;
  }

  .photo_box {
    align-items: center;
    border: 2px solid blue;
    height: 100px;
    width: 100px;
    position: relative;
    right: 32%;
    top: 20%;
  }
  .img__text {
    margin: 0;
  }
  .photo_text {
    padding-top: 240px;
  }
  .gallery__title {
    margin-top: 2rem;
    font-size: 1rem;
    text-align: center;
  }
  #Gallery_Info {
    width: 95%;
    padding-top: 20px;
    margin-top: 65px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    margin-left: 2%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    color: white;
  }
  #gallery_para {
    width: auto;
    height: 100%;
    padding: 3px 200px 10px 200px;
  }
  #recent_out {
    background-color: rgb(212, 212, 136);
    padding: 4%;
    margin: 1rem;
    width: auto;
    height: 100vh;
    position: relative;
    border: 5px black solid;
  }
  #gal {
    background-color: rgb(193, 106, 6);
    text-align: left;
    color: white;
    padding: 5%;
    height: 100%;
    background-color: black;
  }
}

@media screen and (max-width: 700px) {
  .gallery__title {
    margin-left: 0.5rem;
  }
  #gallery_para {
    margin: auto;
    width: 100%;
    padding: 1rem;
  }
  .photo_test {
    width: 50vw;
    height: 25vh;
    border: 2px black solid;
    margin: 1% 0;
  }
  #Gallery_Info {
    height: auto;
    width: auto;
    margin: 1%;
    margin-top: 80px;
    background-color: black;
    color: white;
  }
  #gal {
    background-color: rgb(193, 106, 6);
    border: 5px white solid;
    margin: 1%;
    display: flex;
    flex-direction: row;
  }
  #recent_out {
    margin: 2rem;
    width: auto;
    height: 100%;
    background-color: rgb(212, 212, 136);
    border: 5px black solid;
  }
}
@media screen and (orientation: landscape) and (max-width: 2250px) {
  #gallery_para {
    margin: auto;
    width: 100%;
    padding: 1rem;
  }
}
