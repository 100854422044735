* {
  margin: 0;
  padding: 0;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
  padding-top: 1rem;
}
h2,
h3,
h5 {
  font-family: "Zen Dots", cursive;
}
