.banner {
  object-fit: contain;
  height: 400px;
  padding: 2px;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
}
.banner__title {
  text-shadow: 0 2px 2px black, 0 0 2.5px rgb(112, 2, 255), 0 1px 0 white,
    0 1.5px 1.5px blue, 1.8px 2.8px 1px white;
}
.banner__description {
  text-shadow: 0 2px 2px black, 0 1px 1px grey, 0 0 2.5px rgb(112, 2, 255);
}
.banner__title {
  font-size: 1.5rem;
}
.banner__description {
  font-size: 11px;
}
.banner__contents {
  padding-top: 190px;
  height: 100px;
  padding-left: 10px;
  margin: 0.5rem;
}
@media screen and (min-width: 700px) {
  .banner {
    color: white;

    object-fit: contain;
    height: 564px;
  }
  .banner__contents {
    padding-top: 240px;
    height: 190px;
    padding-left: 40px;
    margin: 1rem;
  }
  .banner__title {
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
    text-shadow: 0 2px 2px black, 0 0 2.5px rgb(112, 2, 255), 0 1px 0 white,
      0 1.5px 1.5px blue, 1.8px 2.8px 1px white;
  }
  .banner__description {
    width: 45rem;
    text-shadow: 0 2px 2px black, 0 1px 1px grey, 0 0 2.5px rgb(112, 2, 255);
    line-height: 1.3;
    padding-top: 0.5rem;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
  }
  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding: 0.5rem 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
  }
  .banner__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.2s;
  }
  .banner__fadebottom {
    height: 7.4rem;
    background-image: linear-gradient(
      180deg,
      transparent,
      rgba(37, 37, 37, 0.61),
      #111
    );
  }
}
